/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/photoswipe.css'

const VeerTowers = () => (
  <>
    <Helmet>
      <title>Veer Towers City Center Motorized Shades and Blackout Shades</title>
      <meta
        name="description"
        content="DropShade can compliment your Veer Tower high rise unit with motorized wall tracker shades featuring VISI Sheer fabrics and Black out fabrics."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="veer-towers" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Veer</span> TOWERS
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DropShade is the preferred vendor for for MGM CityCenter and Veer Towers. Contact us to see what is the best solution for your condo.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/veer-towers/veer-towers-1.jpg')}
                thumbnail={require('../../assets/images/veer-towers/veer-towers-1-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/veer-towers/veer-towers-1-sm.jpg')} alt="Veer Towers" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/veer-towers/veer-towers-2.jpg')}
                thumbnail={require('../../assets/images/veer-towers/veer-towers-2-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/veer-towers/veer-towers-2-sm.jpg')} alt="Veer Towers" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/veer-towers/veer-towers-3.jpg')}
                thumbnail={require('../../assets/images/veer-towers/veer-towers-3-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/veer-towers/veer-towers-3-sm.jpg')} alt="Veer Towers" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/veer-towers/veer-towers-4.jpg')}
                thumbnail={require('../../assets/images/veer-towers/veer-towers-4-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/veer-towers/veer-towers-4-sm.jpg')} alt="Veer Towers" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default VeerTowers
