/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Video } from '@splidejs/splide-extension-video'
import { Gallery, Item } from 'react-photoswipe-gallery'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const ExteriorShades = () => (
  <>
    <Helmet>
      <title>Patio Drop Shades, Exterior Motorized Retractable Shades</title>
      <meta
        name="description"
        content="Our motorized  or crank operated exterior shades will block the suns heat and glare off your patio, windows and doors."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/exterior-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="shade-page" />
    </Helmet>

    <main>
      <Splide
        id="splideHeaderVideo"
        options={{
          autoHeight: true,
          arrows: false,
          cover: true,
          pagination: false,
          video: {
            autoplay: false,
            mute: false,
            playerOptions: {
              youtube: {
                playlist: undefined
              }
            },
            loop: true
          }
        }}
        extensions={{ Video }}
        aria-label="Header Video"
      >
        <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=9_rrNatJDds&list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL&t=1s">
          <img src={require('../../assets/images/slides/home/slide-2.jpg')} alt="Exterior Shade Header Video" />
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Exterior</span> SHADES
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE offers a wide variety of custom patio shades that are made of extremely high-quality materials for many outdoor situations. Our
            manual or motorized shading solutions will control light, glare and heat exposure at the push of a button or turn of a crank. You'll
            protect valuable possessions from solar damage by blocking up to 99% of harmful UV rays. You'll increase energy savings... and enjoy
            greater privacy... with shades that allow excellent outward visibility and ventilation. Also, with the occasional strong winds and rain,
            our shades can help to protect you from flying debris, dust, rain and even golf balls.
          </p>
          <p className="paragraph-text">
            DROPSHADE retractable shades are custom manufactured with the finest quality materials, and come with a 15-year guarantee. They can span
            up to 35 ft wide with a 20 ft fabric drop in a single unit.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-70.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-70-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-70-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-69.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-69-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-69-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-68.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-68-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-68-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-67.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-67-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-67-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-01.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-01-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-01-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-02.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-02-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-02-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-03.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-03-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-03-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-04.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-04-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-04-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-shade-1.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-1sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-shade-1sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-shade-2.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-1sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-shade-1sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-05.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-2sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-05-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-06.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-06-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-06-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/drop-shade-2016-01.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-2016-01sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/drop-shade-2016-01sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/drop-shade-2016-02.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-2016-02sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/drop-shade-2016-02sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-07.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-07-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-07-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-08.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-08-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-08-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-09.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-09-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-09-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-10.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-10-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-10-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-11.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-11-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-11-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-12.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-12-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-12-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-13.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-13-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-13-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-14.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-14-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-14-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-15.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-15-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-15-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-16.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-16-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-16-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-40.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-40sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-40sm.jpg')} alt="2n1 Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-41.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-40sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-40sm.jpg')} alt="2n1 Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-42.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-41sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-41sm.jpg')} alt="2n1 Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-43.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-42sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-42sm.jpg')} alt="2n1 Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-17.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-43sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-43sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-18.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-18-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-18-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-19.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-19-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-19-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-20.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-20-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-20-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-21.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-21-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-21-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-22.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-22-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-22-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-23.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-23-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-23-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-24.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-24-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-24-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-25.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-25-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-25-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-26.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-26-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-26-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-27.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-27-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-27-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-28.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-28-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-28-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-29.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-29-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-29-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-30.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-30-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-30-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-31.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-31-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-31-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-32.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-32-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-32-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-33.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-33-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-33-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-34.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-34-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-34-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-35.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-35-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-35-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-36.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-36-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-36-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-37.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-37-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-37-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-38.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-38-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-38-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-39.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-39-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-39-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-40.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-40-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-40-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-41.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-41-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-41-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-42.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-42-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-42-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-43.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-43-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-43-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-44.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-44-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-44-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-45.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-45-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-45-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-46.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-46-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-46-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-47.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-47-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-47-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-48.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-48-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-48-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-49.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-49-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-49-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-50.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-50-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-50-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-51.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-51-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-51-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-52.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-52-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-52-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-53.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-53-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-53-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-54.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-54-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-54-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-55.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-55-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-55-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-56.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-56-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-56-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-57.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-57-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-57-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-58.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-58-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-58-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-60.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-60-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-60-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-61.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-61-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-61-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/drop-shade-restaurant-01.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-restaurant-01sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/drop-shade-restaurant-01sm.jpg')}
                    alt="Patio Drop Shade"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/drop-shade-restaurant-02.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-restaurant-02sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/drop-shade-restaurant-02sm.jpg')}
                    alt="Patio Drop Shade"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/drop-shade-restaurant-03.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-restaurant-03sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/drop-shade-restaurant-03sm.jpg')}
                    alt="Patio Drop Shade"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-62.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-62-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/patio-drop-shade-62-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-11.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-11-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-11-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-16.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-11-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-11-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/window-shades/window-drop-shade-13.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-16-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/2-n-1-shades/2n1-dropshade-16-sm.jpg')}
                    alt="Window Dropshade Images"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/window-shades/window-drop-shade-12.jpg')}
                thumbnail={require('../../assets/images/window-shades/window-drop-shade-13-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/window-shades/window-drop-shade-13-sm.jpg')}
                    alt="Window Dropshade Images"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/window-shades/window-drop-shade-14.jpg')}
                thumbnail={require('../../assets/images/window-shades/window-drop-shade-12-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/window-shades/window-drop-shade-12-sm.jpg')}
                    alt="Window Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/solar-screens/solar-screens-1.jpg')}
                thumbnail={require('../../assets/images/window-shades/window-drop-shade-14-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/solar-screens/solar-screens-1-sm.jpg')} alt="Patio Drop Shade" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default ExteriorShades
