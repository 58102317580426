/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'

const WindLoadChart = () => (
  <>
    <Helmet>
      <title>Dropshade Wind Load Chart</title>
      <meta
        name="description"
        content="Dropshade offers a large selection of shade motors, controls and solar fabrics. Such as Somfy, Nice, Maddog 20, Texteline, Phifer, VISI any many others."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="wind-load-chart-page" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Wind Load</span> Chart
            </h2>
            <p />
          </section>
          <p className="paragraph-text">Dropshade Certified Engineered Wind Loads.</p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <div
            style={{
              width: '100%',
              maxWidth: '750px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              margin: '0 auto'
            }}
          >
            <img src={require('../../assets/images/DROPSHADE-wind-load-doc-sta.jpg')} width="100%" />
          </div>
          {/* <!-- <div class="wind-load-chart">
                  <p class="height">Dropshade Height</p>
                  <p class="height-val"></p>
                  <p class="height-val"></p>
                  <p class="height-val border">8'</p>
                  <p class="height-val border">10'</p>
                  <p class="height-val"></p>
                  <p class="width"><span>DropShade Width</span></p>
                  <p class="width-val">6'</p>
                  <p class="speed-val">75</p>
                  <p class="speed-val">72</p>
                  <p class="speed"><span>Wind Speed (MPH)</span></p>
                  <p class="width-val">8'</p>
                  <p class="speed-val">63</p>
                  <p class="speed-val">59</p>
                  <p class="width-val">10'</p>
                  <p class="speed-val">56</p>
                  <p class="speed-val">51</p>
                  <p class="width-val">12'</p>
                  <p class="speed-val">49</p>
                  <p class="speed-val">47</p>
                  <p class="width-val">16'</p>
                  <p class="speed-val">47</p>
                  <p class="speed-val">40</p>
                  <p class="width-val">18'</p>
                  <p class="speed-val">45</p>
                  <p class="speed-val">44</p>
                  <p class="width-val">20'</p>
                  <p class="speed-val">41</p>
                  <p class="speed-val">38</p>
                  <p class="width-val">24'</p>
                  <p class="speed-val">38</p>
                  <p class="speed-val">36</p>
                  <p class="width-val">28'</p>
                  <p class="speed-val">36</p>
                  <p class="speed-val">35</p>
                  <p class="width-val">32'</p>
                  <p class="speed-val">35</p>
                  <p class="speed-val">34</p>
                </div> --> */}
        </div>
      </section>
    </main>
  </>
)
export default WindLoadChart
