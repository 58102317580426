/* eslint-disable react/no-unknown-property */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
// import useScript from '../../hooks/useScript'

const ContactUs = () => {
  // Set Required Fields
  const captchaRef = useRef(null)
  const inptTxtReqAry = ['name', 'phone', 'email']

  const handleBlur = (e) => {
    console.log(e.currentTarget)
    if (e.currentTarget.name === 'email') {
      checkEmail(e.currentTarget)
    } else {
      checkVal(e.currentTarget)
    }
  }
  const handleSubmit = (e) => {
    // Form Submit  Handler
    e.preventDefault()
    const reCaptchaToken = captchaRef.current.getValue()
    captchaRef.current.reset()
    document.querySelector('.validation-error-msg').classList.add('hidden')
    document.querySelector('.validation-error-msg').innerHTML = 'Missing some required fields.'
    document.querySelector('.btn-contact-submit').classList.add('btn-processing')

    // Check Validation
    let valid = true

    // Loop through Text Inputs and validate
    inptTxtReqAry.forEach((value) => {
      if (!checkVal(document.querySelector(`input[name=${value}]`))) valid = false
    })

    // Check email
    if (!checkEmail(document.querySelector('input[name=email]'))) valid = false

    // If no validation errors proceed
    if (valid) {
      const formData = new FormData()
      formData.append('name', document.getElementById('name').value)
      formData.append('phone', document.getElementById('phone').value)
      formData.append('email', document.getElementById('email').value)
      formData.append('comments', document.getElementById('comments').value)
      formData.append('g-recaptcha-response', reCaptchaToken)

      fetch('/php/contact-form-send.php', {
        method: 'POST',
        body: formData
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('Success:', result)
          if (result.status === 'success') {
            document.querySelector('.success-message').classList.remove('hidden')
            document.querySelector('.contact-form').classList.add('hidden')
          } else if (result.status === 'captchaFail') {
            document.querySelector('.validation-error-msg').classList.remove('hidden')
            document.querySelector('.validation-error-msg').innerHTML = 'You must confirm you are not a robot.'
          } else {
            console.error('Error:', 'Mail Failed')
            document.querySelector('.validation-error-msg').classList.remove('hidden')
            document.querySelector('.validation-error-msg').innerHTML = 'Mail failed to send. Please try again.'
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
        .finally(() => {
          document.querySelector('.btn-contact-submit').classList.remove('btn-processing')
          document.querySelector('.validation-error-msg').scrollIntoView({ behavior: 'smooth' })
        })
    } else {
      console.log('Validation Failed')
      document.querySelector('.validation-error-msg').classList.remove('hidden')
      document.querySelector('.btn-contact-submit').classList.remove('btn-processing')
      document.querySelector('.validation-error-msg').scrollIntoView({ behavior: 'smooth' })
    }
    return false
  }

  // Check Input Value
  const checkVal = (element) => {
    if (!element.value) {
      element.parentNode.classList.add('input-error')
      return false
    }
    element.parentNode.classList.remove('input-error')
    return true
  }

  // Check Email
  const checkEmail = (element) => {
    if (!isEmail(element.value)) {
      element.parentNode.classList.add('input-error')
      if (!element.value) {
        element.nextElementSibling.innerHTML = 'Required'
      } else {
        element.nextElementSibling.innerHTML = 'Email is invalid'
      }
      return false
    }
    element.parentNode.classList.remove('input-error')
    return true
  }

  // Check Email is an email
  const isEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
  }

  return (
    <>
      <Helmet>
        <title>Contact Drop Shade in Las Vegas Henderson and Dallas Fort Worth</title>
        <meta
          name="description"
          content="Dropshade has a factory location in Las Vegas serving Henderson, and Dallas Fort Worth area serving Texas."
        />
        <meta
          name="keywords"
          content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades, las vegas , dallas, henderson, fort worth, texas"
        />
        <meta property="og:title" content="DropShade" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dropshade.com" />
        <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
        <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
        <meta property="og:site_name" content="DropShade" />
        <body data-page="contact-us" />
      </Helmet>
      <main>
        <a name="quoteForm" id="quoteForm" />
        <section className="content-section-wrapper content-section-bg-b">
          <div className="content-section">
            <section className="section-title">
              <h2>
                <span>Free</span> Quote
              </h2>
              <p />
            </section>
            <p className="paragraph-text">Fill out the form below and tell us your needs for a free quote.</p>
            <div className="success-message hidden">Thank You! We will be in touch shortly.</div>
            <div className="validation-error-msg hidden">Missing some required fields.</div>
            <form id="contactForm" name="contact" className="contact-form" onSubmit={handleSubmit}>
              <div className="input-wrapper grid-span-all">
                <label htmlFor="name">* Name</label>
                <input type="text" name="name" id="name" onBlur={handleBlur} />
                <p className="input-error-msg">Required</p>
              </div>
              <div className="input-wrapper">
                <label htmlFor="phone">* Phone</label>
                <input type="text" name="phone" id="phone" onBlur={handleBlur} />
                <p className="input-error-msg">Required</p>
              </div>
              <div className="input-wrapper grid-span-2">
                <label htmlFor="email">* Email Address</label>
                <input type="text" name="email" id="email" onBlur={handleBlur} />
                <p className="input-error-msg">Required</p>
              </div>
              <div className="input-wrapper grid-span-all">
                <label htmlFor="comments">Comments</label>
                <textarea name="comments" id="comments" rows="5" />
                <p className="input-error-msg">Required</p>
              </div>
              <div className="input-wrapper grid-span-all">
                <ReCAPTCHA sitekey="6LeSqZwUAAAAANNsJgJoCeXdKaZqjoRM1Km8tEIU" ref={captchaRef} />
                {/* <div className="g-recaptcha" data-sitekey="6LeSqZwUAAAAANNsJgJoCeXdKaZqjoRM1Km8tEIU" />
                <div
                  id="captcha_error"
                  style={{
                    padding: '20px',
                    marginTop: '10px',
                    color: 'white',
                    backgroundColor: 'red',
                    display: 'none'
                  }}
                /> */}
              </div>
              <div className="input-wrapper grid-span-all">
                <button type="submit" className="btn btn-alt-color btn-contact-submit">
                  <span className="btn-text">Submit</span>
                  <span className="btn-spinner" />
                </button>
              </div>
            </form>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-a">
          <div className="content-section">
            <section className="section-title">
              <h2>
                <span>Contact</span> Us
              </h2>
              <p />
            </section>
            <p className="paragraph-text">To get a free quote please call, text or fill out the contact form below.</p>
            <p className="paragraph-text" style={{ fontSize: '30px' }}>
              Text us on our shop iPhone{' '}
              <a href="sms:7022772349" style={{ whiteSpace: 'nowrap' }}>
                702-277-2349
              </a>
            </p>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-b">
          <div className="content-section">
            <section className="address-grid">
              <div className="address">
                <p>Las Vegas Office</p>
                <p>2547 E. Washburn Rd.</p>
                <p>North Las Vegas, NV 89081</p>
                <div className="phone-numbers">
                  <p>
                    <a href="tel:7024728045">(702) 472-8045</a> <span>(Las Vegas)</span>
                  </p>
                  <p>
                    <a href="tel:7024567742">(702) 456-7742</a> <span>(Henderson)</span>
                  </p>
                  <p>
                    <a href="sms:7022772349">(702) 277-2349</a> <span>(Text Shop iPhone)</span>
                  </p>
                </div>
              </div>
              <div className="map-wrapper">
                <iframe
                  title="Las Vegas Map"
                  className="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3217.4436228139884!2d-115.11793168443796!3d36.25300730600817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8e815d8fbc645%3A0x49ede689bf271c8a!2sDrop%20Shade!5e0!3m2!1sen!2sus!4v1672692291284!5m2!1sen!2sus"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </section>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-a">
          <div className="content-section">
            <section className="address-grid">
              <div className="address">
                <p>Texas Office</p>
                <p>14001 Distribution Way</p>
                <p>Farmers Branch, TX 75234</p>
                <div className="phone-numbers">
                  <p>
                    <a href="tel:2143100222">(214) 310-0222</a> <span>(Dallas/Fort Worth)</span>
                  </p>
                  <p>
                    <a href="tel:8329561940">(281) 407-0830</a> <span>(Houston)</span>
                  </p>
                  <p>
                    <a href="tel:2108019438">(210) 853-2420</a> <span>(San Antonio)</span>
                  </p>
                  <p>
                    <a href="tel:5123754300">(512) 501-2270</a> <span>(Austin)</span>
                  </p>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <a href="texas-office.html">Texas Gallery</a>
                </div>
              </div>
              <div className="map-wrapper">
                <iframe
                  title="Texas Map"
                  className="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33749.1961177788!2d-96.92601577692103!3d32.9419122684943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c27480af10707%3A0x1e63f808295e0a6a!2sDropShade!5e0!3m2!1sen!2sus!4v1672692559543!5m2!1sen!2sus"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </section>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-b">
          <div className="content-section">
            <section className="address-grid">
              <div className="address">
                <p>Arizona Office</p>
                <p>Coming Soon</p>
                <p>License # ROC #342770, #342769</p>
                <div className="phone-numbers">
                  <p>
                    <a href="tel:4805360802">(480) 536-0802</a> <span>(Arizona)</span>
                  </p>
                </div>
              </div>
              <div className="map-wrapper">
                {/* <iframe title="Arizona Map" class="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33749.1961177788!2d-96.92601577692103!3d32.9419122684943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c27480af10707%3A0x1e63f808295e0a6a!2sDropShade!5e0!3m2!1sen!2sus!4v1672692559543!5m2!1sen!2sus" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              </div>
            </section>
          </div>
        </section>
      </main>
    </>
  )
}
export default ContactUs
