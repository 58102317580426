/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Video } from '@splidejs/splide-extension-video'
import { Gallery, Item } from 'react-photoswipe-gallery'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const Testimonials = () => (
  <>
    <Helmet>
      <title>Drop Shade Testimonials - Retractable Solar Shades Sun Control Quality Control</title>
      <meta name="description" content="Here is a list of videos and testimonials of our previous work in the Las Vegas and Texas area." />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="testimonials" />
    </Helmet>

    <main>
      <Splide
        id="splideHeaderVideo"
        options={{
          autoHeight: true,
          arrows: false,
          cover: true,
          pagination: false,
          video: {
            autoplay: false,
            mute: false,
            playerOptions: {
              youtube: {
                playlist: undefined
              }
            },
            loop: true
          }
        }}
        extensions={{ Video }}
        aria-label="Header Video"
      >
        <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=hQ32URkxKZw&list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL">
          <img src={require('../../assets/images/slides/testimonials/testimonials-slide-1.jpg')} alt="Testimonials Header Video" />
        </SplideSlide>
        <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=H2sGOePSxw0&list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL&t=24s">
          <img src={require('../../assets/images/slides/testimonials/testimonials-slide-2.jpg')} alt="Testimonials Header Video" />
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Customer</span> Testimonials
            </h2>
            <p />
          </section>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "No more sun in the house. Thank's DropShade!"
            <br />
            <span className="testimonial-name">Al Fried</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "It is nice dealing with the factory outlet. Your team has done an excellent job of outfitting our sales office with shades that assists
            in blocking the heat from the sun. As a side note, many residents have also expressed satisfaction with your work on a daily basis. Thanks
            for your assistance and great work."
            <span className="testimonial-pic">
              <img src={require('../../assets/images/testimonials/panorama-towers.jpg')} alt="Panorama Towers" />
            </span>
            <span className="testimonial-name">Tony Preus, Sales Manager - Panorama Towers</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "Dear Dropshade,
            <br />
            Living at Madeira Canyon in Anthem, we just wanted to express our complete satisfaction with the drop shade system. Our home is situated
            up on the hill where the wind can be horrendous at times. Since the installation a couple of weeks ago we have left the shades in the down
            position and they have been working great. Last night we had a horrible wind storm, and we remembered that we still left the shades in the
            down position. We knew that the shades worked well in the wind, however; that night the winds started to pick up and we were quite
            concerned as some of the lawn furniture was blowing around the yard (gusts of 55 plus). We checked on the shades and were surprised at how
            well they continued to hold in these high winds, in fact it actually helped protect the furniture that was behind it.
            <br />I realize that you recommend to roll them up during these types of extreme conditions but I just wanted to let you know how well
            they did when put to the test. They operated flawlessly. Thank you for a great product that works well and does the job. Any time you
            should require a reference, please do not hesitate to call on us."
            <span className="testimonial-pic">
              <img src={require('../../assets/images/testimonials/jim-cathy.jpg')} alt="Jim and Cathy" />
            </span>
            <span className="testimonial-name">Jim and Cathy, The Club at Madeira Canyon</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "Dropshade, Thanks for the good quality product and excellent customer service!"
            <span className="testimonial-pic">
              <img src={require('../../assets/images/testimonials/sam.jpg')} alt="Sam Schmidt" />
            </span>
            <span className="testimonial-name">Sam Schmidt, "Sam Schmidt Motor sports"</span>
          </p>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/samshouse/sam-large.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam.jpg')} alt="Sam Schmidt's House" />}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam1-large.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam1.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam1.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam2.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam2.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam2.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam3.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam3.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam3.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam4.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam4.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam4.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam7-large.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam7.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam7.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam8.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam8.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam8.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sam9.jpg')}
                thumbnail={require('../../assets/images/samshouse/sam9.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sam9.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/samshouse/sampic-large.jpg')}
                thumbnail={require('../../assets/images/samshouse/sampic.jpg')}
                width="575"
                height="431"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/samshouse/sampic.jpg')} alt="Sam Schmidt's House" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "Good Job Dropshade"
            <span className="testimonial-pic">
              <img src={require('../../assets/images/testimonials/siegfried-roy.jpg')} alt="Siegfried & Roy" />
            </span>
            <span className="testimonial-name">Magically, Siegfried & Roy</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "Hi, Just a note to let you know how thrilled I am with my drop shades. Everyone that I have dealt with from Dropshade has been
            professional, organized and helpful. From the first day I came into your factory I was treated like one of the family. Thanks once again
            for the excellent job and outstanding product - my patio is now complete."
            <span className="testimonial-name">Carol Clemens</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "No more sunglasses! I think that Dropshade is going to put the real effort into whatever project they do. They certainly did here."
            <span className="testimonial-name">Pastor Thomas Lampella, Lutheran Church and School</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "Great shades! Have had these shades for the past two summers. They work as well now as they did when they were first installed. With the
            eastern and western exposure on our windows, the Drop Shades were absolutely the best investment we made for our new home. Not only did it
            protect our furniture from the sun, but, it actually cooled down our home during the hot summer months."
            <span className="testimonial-name">Trisha Stone</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "Dear Dropshade, We love the privacy and the ability to use our patio in all times of the day. Thanks."
            <span className="testimonial-name">Pam Desmione</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "To the boy's at Dropshade! I thought I was cool before, but now I'm really cool!"
            <span className="testimonial-name">Joey Scinta, "The Scintas"</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "Hey Dropshade! Thanks for throwing me a great deal."
            <span className="testimonial-name">Rollie Fingers, "Baseball Hall of Fame Pitcher"</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "Dear Dropshade, Enclosed is a photo of my kitchen nook with the exterior drop shades partially down, you can see by the glare on the wall
            the difference the shades make in reducing the heat from the afternoon sun. In fact, it's actually easier to see out when the shades are
            down.
            <br />
            <br />
            I'm impressed by the quality and workmanship shown by your company. I'm certain you have no difficulty with endorsements, but should you
            have need for my assistance in this area, please don't hesitate to call me, I would be more than happy to oblige."
            <span className="testimonial-name">Eva Beth, Henderson - Anthem</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "I just wanted to drop a line and let you know that the shades your company installed have worked wonders for reducing the heat issue that
            we had with our storefront glass.
            <br />
            <br />
            I really appreciated your expedited service from start to finish, and I would definitely recommend your company to anyone inquiring about
            dropshades. I'm really impressed how well they compliment the buildings esthetics, and the quality of craftsmanship on the shade
            installation.
            <br />
            <br />
            Thanks again for your recommendations and input on the initial design, and a great product that has made a noticeable difference in our
            work environment. "<span className="testimonial-name">Vincent J. Ciminise, President - Bank of Las Vegas</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "Dear Dropshade, I would like to express how satisfied we are on the quality product you have installed on our home in Anthem. Our patio
            opening is extremely wide (27 foot to be exact) and the way the sun was coming in was making it unbearable to sit outside. Your product
            has helped us with a solution to this problem and in addition helps with the wind too. Thanks for a high quality product that upholds a
            high standard of engineering and design."
            <span className="testimonial-pic">
              <img src={require('../../assets/images/testimonials/valerian.jpg')} alt="Valerian Podmolick" />
            </span>
            <span className="testimonial-name">Valerian Podmolick</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "WOW! what a difference our shades make! We have been looking at drop shades for our patio and I'm glad we decided to give it a try. Now
            we can actually enjoy our patio even more in the hot scorching heat."
            <span className="testimonial-name">Norma & Greg J.</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "The patio drop shades are wonderful at reducing the heat we were getting with the afternoon sun. We can now enjoy our evening dinners on
            our patio. Thank you for a job well done."
            <span className="testimonial-name">Hannah Mcfarland</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <p className="paragraph-text">
            "To Dropshade, For once, it's great being in the shade. Great Job!"
            <span className="testimonial-pic">
              <img src={require('../../assets/images/testimonials/wayne-newton-profile.jpg')} alt="Wayne Newton" />
            </span>
            <span className="testimonial-name">Wayne Newton</span>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <p className="paragraph-text">
            "Since having your drop shades installed we have enjoyed a very good savings on our power bill, and recommend them to everyone. They cut
            the glare coming into the house, and save the fading of the furniture & carpet. Thanks DropShade,"
            <span className="testimonial-name">Nate Finnoccio</span>
          </p>
        </div>
      </section>
    </main>
  </>
)
export default Testimonials
