/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'

const DIY = () => (
  <>
    <Helmet>
      <title>DIY Shade Kits</title>
      <meta name="description" content="Dropshade offers a large selection of diy shade kits" />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, diy shade kits" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="diy-page" />
    </Helmet>

    <main>
      <Splide
        id="splideHeaderImages"
        options={{
          type: 'loop',
          rewind: false,
          width: '100vw',
          autoHeight: true,
          cover: true,
          lazyLoad: 'nearby',
          speed: 500,
          interval: 3000,
          autoplay: true,
          pauseOnHover: false,
          pauseOnFocus: true,
          throttle: 0,
          breakpoints: {
            1200: {
              arrows: false
            }
          },
          gap: 0,
          padding: 0
        }}
        aria-label="Header Images"
      >
        <SplideSlide>
          <img src={require('../../assets/images/slides/diy/diy-slide-1.jpg')} alt="DIY Header Slide" />
          <div className="slide-tag">
            <p className="heading-one">DIY KITS</p>
          </div>
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>DIY </span>Shipping Kits
            </h2>
            <p />
          </section>
          <p className="paragraph-text">"We can custom build our shades and ship directly to you"</p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <div className="diy-shade-wrapper left">
            <div className="img-wrapper">
              <img src={require('../../assets/images/diy/shade-1.jpg')} />
            </div>
            <p className="paragraph-text">
              If you live out of our service area... no problem... We can ship a high quality shade to you, and up to a 35 foot shade in a single
              unit. Give us a call and we can give you options on which shade would work best for your patio. Then we can put together a kit that
              works just for you, with all the necessary hardware for you to install the shade with minimal effort.
            </p>
          </div>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <div className="diy-shade-wrapper right">
            <p className="paragraph-text">
              Choose between our patio shade with tracks or cables or the 2n1 shade with projection arms. We also offer a vast amount of hand and wall
              mounted touch controls and a option for a phone operated device so you can use your cell phone.
            </p>
            <div className="img-wrapper">
              <img src={require('../../assets/images/diy/shade-2.jpg')} />
            </div>
          </div>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <div className="diy-shade-wrapper left">
            <div className="img-wrapper">
              <img src={require('../../assets/images/diy/shade-3.jpg')} />
            </div>
            <p className="paragraph-text">
              More than just sun protection. The durability of our fabrics, head tube and tensioning system makes our shades extremely strong. They
              are also engineered for heavy wind loads. This makes them great for slowing the heavy wind gusts that you get. Not flapping around like
              other products. We use the same quality components in our head box and side tracks that come in the huricane security shutters. Our
              shades are built to last and include a 15 year guarantee.
            </p>
          </div>
        </div>
      </section>
    </main>
  </>
)
export default DIY
