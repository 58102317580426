/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Video } from '@splidejs/splide-extension-video'
import { Gallery, Item } from 'react-photoswipe-gallery'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const TwonOneShades = () => (
  <>
    <Helmet>
      <title>2 n 1 Drop Shade - Retractable Cafe Awning to Patio Shade</title>
      <meta
        name="description"
        content="Retract out like a cafe awning or drop to a patio shade.  The 2 n 1 will increase your patio space while protecting it from the elements and the sun."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/2n1-cover-2.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="shade-page" />
    </Helmet>
    <main>
      <Splide
        id="splideHeaderVideo"
        options={{
          autoHeight: true,
          arrows: false,
          cover: true,
          pagination: false,
          video: {
            autoplay: false,
            mute: false,
            playerOptions: {
              youtube: {
                playlist: undefined
              }
            },
            loop: true
          }
        }}
        extensions={{ Video }}
        aria-label="Header Video"
      >
        <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=k7IzNEEqsKs">
          <img src={require('../../assets/images/slides/home/slide-1.jpg')} alt="2n1 Shade Header Video" />
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>2n1</span> SHADES
            </h2>
            <p />
          </section>
          <p className="paragraph-text">An Awning and Drop Shade combined!</p>
          <p className="paragraph-text">
            Get the best in sun, wind and rain protection with the 2n1 DropShade. With the flexibility to project out like an awning it will expand
            your living space and coverage. Or, at the push of a button, it can drop down to a patio shade! The 2N1 can be put in many positions and
            configurations to give you the perfect shade throughout the day. With its' stainless steel structure and high tensile fabrics, it's the
            strongest retractable shade on the market today. Contact us for more information and free estimate.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-63.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-63-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-63-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-62.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-62-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-62-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-61.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-61-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-61-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-60.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-60-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-60-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-59.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-59-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-59-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-58.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-58-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-58-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/20180119_DS-PineHollow_01.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/20180119_DS-PineHollow_01-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/2-n-1-shades/20180119_DS-PineHollow_01-sm.jpg')}
                    alt="2-n-1 Shades"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/20180119_DS-PineHollow_03.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/20180119_DS-PineHollow_03-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/2-n-1-shades/20180119_DS-PineHollow_03-sm.jpg')}
                    alt="2-n-1 Shades"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/GarnetHills_DS-02-web.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/GarnetHills_DS-02-web-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/GarnetHills_DS-02-web-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/GarnetHills_DS-07-web.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/GarnetHills_DS-07-web-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/GarnetHills_DS-07-web-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/20201030_DS-RedArrow-03.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/20201030_DS-RedArrow-03-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/20201030_DS-RedArrow-03-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/20181105_DS-WhitesFerry-5_v2-web.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/20181105_DS-WhitesFerry-5_v2-web-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/2-n-1-shades/20181105_DS-WhitesFerry-5_v2-web-sm.jpg')}
                    alt="2-n-1 Shades"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/20181105_DS-WhitesFerry-web-2.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/20181105_DS-WhitesFerry-web-2-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/2-n-1-shades/20181105_DS-WhitesFerry-web-2-sm.jpg')}
                    alt="2-n-1 Shades"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/20170728_DS-Stansbury-01-web.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/20170728_DS-Stansbury-01-web-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/2-n-1-shades/20170728_DS-Stansbury-01-web-sm.jpg')}
                    alt="2-n-1 Shades"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-shade-1.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-1sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-shade-1sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-shade-2.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-2sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-shade-2sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-shade-3.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-3sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-shade-3sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-shade-4.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-shade-4sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-shade-4sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-40.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-40sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-40sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-41.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-41sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-41sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-42.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-42sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-42sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-43.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-43sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-43sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-44.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-44sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-44sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-45.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-45sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-45sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-49.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-49sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-49sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-50.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-50sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-50sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-53.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-53sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-53sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-52.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-52sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-52sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-56.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-56sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-56sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-54.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-54sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-54sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-01.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-01-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-01-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-02.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-02-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-02-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-29.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-29-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-29-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-30.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-30-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-30-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-46.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-46sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-46sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-48.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-48sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-48sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-05.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-05-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-05-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-06.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-06-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-06-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-07.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-07-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-07-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-08.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-08-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-08-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-09.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-09-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-09-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-10.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-10-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-10-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-34.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-34-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-34-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-35.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-35-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-35-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-18.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-18-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-18-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-19.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-19-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-19-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-32.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-32-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-32-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-31.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-31-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-31-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-33.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-33-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-33-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-36.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-36-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-36-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/drop-shade-2n1-window-01.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/drop-shade-2n1-window-01sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/drop-shade-2n1-window-01sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>

              <Item
                original={require('../../assets/images/2-n-1-shades/drop-shade-2n1-window-02.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/drop-shade-2n1-window-02sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/drop-shade-2n1-window-02sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default TwonOneShades
