/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Video } from '@splidejs/splide-extension-video'
import { Gallery, Item } from 'react-photoswipe-gallery'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const About = () => (
  <>
    <Helmet>
      <title>Dropshade Sun Control Sytems</title>
      <meta
        name="description"
        content="Welcome to DropShade. From interior shades and exterior awnings to patio screens, the DropShade brand has become the undisputed leader of sun control systems. Creating comfort wherever needed, our custom designs add that extra special �wow� factor that sets your home or business apart."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="about-us" />
    </Helmet>

    <main>
      <Splide
        id="splideHeaderVideo"
        options={{
          autoHeight: true,
          arrows: false,
          cover: true,
          pagination: false,
          video: {
            autoplay: false,
            mute: false,
            playerOptions: {
              youtube: {
                playlist: undefined
              }
            },
            loop: true
          }
        }}
        extensions={{ Video }}
        aria-label="Header Video"
      >
        <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=XfHvnCDaO9c&list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL">
          <img src={require('../../assets/images/aboutus/aboutus-video-preview.jpg')} alt="About Us Header Video" />
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>About</span> Us
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE is family-owned and operated. Tradition, integrity and superior customer service have made us a leading manufacturer of sun
            control systems in Las Vegas. We are continuously the number-one choice of both contractors and homeowners when it comes to their shading
            needs.
          </p>
          <p className="paragraph-text">
            DROPSHADE started with the simple philosophy of providing custom sun control systems with optimum service at competitive prices for our
            customers. Since then, we have grown into an innovative shade and awning manufacturer. Our commitment to customer satisfaction has helped
            us to become a leader in shade production across the United States.
          </p>
          <p className="paragraph-text">
            For over 20 years we've been a renowned innovator of shading materials, structural designs and installation methods that have been adopted
            by the industry as a whole. Today, DROPSHADE continues to be family-owned and operated and committed to providing exceptional products and
            craftsmanship at an affordable price.
          </p>
          <p className="paragraph-text">
            Feel free to give us a call for more information about any one of our drop shade systems or to schedule a tour of our factory anytime.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Meet The</span> Team
            </h2>
            <p />
          </section>
          <Gallery
            options={{
              // zoom: false,
              // close: false,
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/aboutus/ds-shop-5-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-5-sm.jpg')}
                width="500"
                height="800"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-5-sm.jpg')} className="vertical-span-2" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-6-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-6-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-6-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-9-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-9-sm.jpg')}
                width="500"
                height="800"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-9-sm.jpg')} className="vertical-span-2" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-44-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-44-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-44-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-19-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-19-sm.jpg')}
                width="500"
                height="800"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-19-sm.jpg')} className="vertical-span-2" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-66-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-66-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-66-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-2-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-2-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-2-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-11-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-11-sm.jpg')}
                width="500"
                height="800"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-11-sm.jpg')} className="vertical-span-2" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-13-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-13-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-13-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-14-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-14-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-14-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-15-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-15-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-15-sm.jpg')} />}
              </Item>
              <Item
                original={require('../../assets/images/aboutus/ds-shop-16-lg.jpg')}
                thumbnail={require('../../assets/images/aboutus/ds-shop-16-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={require('../../assets/images/aboutus/ds-shop-16-sm.jpg')} />}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default About
