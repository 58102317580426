/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Gallery, Item } from 'react-photoswipe-gallery'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const FactoryTour = () => (
  <>
    <Helmet>
      <title>Quality Standards - Drop Shade</title>
      <meta name="description" content="Custom interior and exterior drop shades for sun control and comfort." />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="factory-tour" />
    </Helmet>

    <main>
      <Splide
        id="splideHeaderImages"
        options={{
          type: 'loop',
          rewind: false,
          width: '100vw',
          autoHeight: true,
          cover: true,
          lazyLoad: 'nearby',
          speed: 500,
          interval: 3000,
          autoplay: true,
          pauseOnHover: false,
          pauseOnFocus: true,
          throttle: 0,
          breakpoints: {
            1200: {
              arrows: false
            }
          },
          gap: 0,
          padding: 0
        }}
        aria-label="Header Images"
      >
        <SplideSlide>
          <img src={require('../../assets/images/slides/factory-tour/factory-tour-slide-1.jpg')} alt="Slide One" />
          <div className="slide-tag">
            <p className="heading-one">QUALITY MATERIALS</p>
          </div>
        </SplideSlide>
        <SplideSlide>
          <img src={require('../../assets/images/slides/factory-tour/factory-tour-slide-3.jpg')} alt="Slide Two" />
          <div className="slide-tag">
            <p className="heading-one">FRIENDLY STAFF</p>
          </div>
        </SplideSlide>
        <SplideSlide>
          <img src={require('../../assets/images/slides/factory-tour/factory-tour-slide-4.jpg')} alt="Factory Tour Slide Four" />
          <div className="slide-tag">
            <p className="heading-one">PRECISION WORK</p>
          </div>
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Quality</span> Standards
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            Simply put, DROPSHADE is the best in the business for interior and exterior shades. No other manufacturer produces the same high-quality,
            custom products. From our award winning designs to our manufacturing team, we at DROPSHADE thoroughly hand test every shade system before
            it leaves our factory, guaranteeing you a superior shade.
          </p>
          <p className="paragraph-text">
            We use high quality materials and crafts them by hand for superior results. With our vast selection of fabrics, your interior or exterior
            shade will enhance the value of your home or business.
          </p>
          <p className="paragraph-text">
            Our shades can block up to 99% of the harmful UV rays, and protect people from sun exposure and personal property from solar damage.
            DROPSHADE fabric options can also reduce air conditioning costs by up to 50%.
          </p>
          <p className="paragraph-text">
            DROPSHADE offers extreme solutions for expansive areas, that can even reach spans up to 35' wide without a break in the seam or the
            fabric. Our secret is in our commercial grade components that are combined with our extra wide fabric overlays and heavy extrusions.
          </p>
          <p className="paragraph-text">
            Please contact us and we will be happy to help and offer the best solutions to whatever project you need shades for.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/factory-tour/ds-shop-4-lg.jpg')}
                thumbnail={require('../../assets/images/factory-tour/ds-shop-4-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/factory-tour/ds-shop-4-sm.jpg')} alt="Drop Shade Factory" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/factory-tour/ds-shop-1-lg.jpg')}
                thumbnail={require('../../assets/images/factory-tour/ds-shop-1-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/factory-tour/ds-shop-1-sm.jpg')} alt="Drop Shade Factory" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/factory-tour/ds-shop-12-lg.jpg')}
                thumbnail={require('../../assets/images/factory-tour/ds-shop-12-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/factory-tour/ds-shop-12-sm.jpg')} alt="Drop Shade Factory" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/factory-tour/ds-shop-16-lg.jpg')}
                thumbnail={require('../../assets/images/factory-tour/ds-shop-16-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/factory-tour/ds-shop-16-sm.jpg')} alt="Drop Shade Factory" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/factory-tour/ds-shop-17-lg.jpg')}
                thumbnail={require('../../assets/images/factory-tour/ds-shop-17-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/factory-tour/ds-shop-17-sm.jpg')} alt="Drop Shade Factory" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/factory-tour/ds-shop-18-lg.jpg')}
                thumbnail={require('../../assets/images/factory-tour/ds-shop-18-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/factory-tour/ds-shop-18-sm.jpg')} alt="Drop Shade Factory" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/why-dropshade/shawn-lg.jpg')}
                thumbnail={require('../../assets/images/why-dropshade/shawn-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/why-dropshade/shawn-sm.jpg')} alt="Why Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/why-dropshade/shade-window-lg.jpg')}
                thumbnail={require('../../assets/images/why-dropshade/shade-window-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/why-dropshade/shade-window-sm.jpg')} alt="Why Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/why-dropshade/lady-lg.jpg')}
                thumbnail={require('../../assets/images/why-dropshade/lady-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/why-dropshade/lady-sm.jpg')} alt="Why Drop Shade" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default FactoryTour
