/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'

const MotorControlOptions = () => (
  <>
    <Helmet>
      <title>Dropshade Motor Control Options</title>
      <meta
        name="description"
        content="Dropshade offers a large selection of shade motors, controls and solar fabrics. Such as Somfy, Nice, Maddog 20, Texteline, Phifer, VISI any many others."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="motors-controls-page" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Motor</span> Control Options
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE offers a vast selection of electric motors, remotes, automation controls, timers and sensors. We are constantly following the
            full spectrum of all new technologies that became available. It is our philosophy that our controls should be easy to use and as
            inconspicuously as possible. By combining high quality products with the most advanced technology available, we give our customers peace
            of mind to create the most accommodating and personalized sun controlled settings exactly when needed.
          </p>
          <p className="paragraph-text">Other options include our popular cordless M-20 motor handle to operate shades with manual controls.</p>
        </div>
      </section>
    </main>
  </>
)
export default MotorControlOptions
