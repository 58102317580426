/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'

const ArizonaOffice = () => (
  <>
    <Helmet>
      <title>Drop Shade Phoenix</title>
      <meta name="description" content="Drop Shade Phoenix - Serving Phoenix Arizona and surrounding areas." />
      <meta
        name="keywords"
        content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades, las vegas , dallas, henderson, fort worth, texas"
      />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="arizona-office" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Drop Shade</span> Phoenix
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE is proudly serving phoenix with our high quality custom products including patio, interior and exterior shade options and
            fabrics. Please contact us for more information and a free quote.
          </p>
          <p className="paragraph-text">
            We serve the greater metro Phoenix area, including Gilbert, Mesa, Tempe, Peoria. Glendale. Scottsdale, Fountain Hills, and Sun City.
          </p>
          <p className="paragraph-text">Our Arizona contractor license numbers are: ROC #342770, #342769</p>
          <p className="paragraph-text" style={{ fontSize: '30px' }}>
            Phone or text us directly{' '}
            <a href="sms:4805360802" style={{ whiteSpace: 'nowrap' }}>
              480-536-0802
            </a>{' '}
            (We love text)
          </p>
        </div>
      </section>
    </main>
  </>
)
export default ArizonaOffice
