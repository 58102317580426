/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/photoswipe.css'

const WaldorfAstoria = () => (
  <>
    <Helmet>
      <title>The Waldorf CityCenter Motorized Shades and Blackout Shades</title>
      <meta
        name="description"
        content="DropShade can compliment your high rise unit at The Waldorf with motorized shades featuring VISI Sheer fabrics and Black out fabrics."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="waldorf-astoria" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Waldorf</span> Astoria
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            Check out the many shades we have done at The Waldorf which was formally The Mandarin Oriental. Please contact us to see what may be the
            best solution for you.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/waldorf/waldorf-1.jpg')}
                thumbnail={require('../../assets/images/waldorf/waldorf-1-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/waldorf/waldorf-1-sm.jpg')} alt="Waldorf Astoria Images" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/waldorf/waldorf-2.jpg')}
                thumbnail={require('../../assets/images/waldorf/waldorf-2-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/waldorf/waldorf-2-sm.jpg')} alt="Waldorf Astoria Images" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/waldorf/waldorf-3.jpg')}
                thumbnail={require('../../assets/images/waldorf/waldorf-3-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/waldorf/waldorf-3-sm.jpg')} alt="Waldorf Astoria Images" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/waldorf/waldorf-4.jpg')}
                thumbnail={require('../../assets/images/waldorf/waldorf-4-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/waldorf/waldorf-4-sm.jpg')} alt="Waldorf Astoria Images" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default WaldorfAstoria
