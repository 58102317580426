/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/photoswipe.css'

const TexasOffice = () => (
  <>
    <Helmet>
      <title>Drop Shade Texas</title>
      <meta name="description" content="Drop Shade Texas - Dallas, Fort Worth, San Antonio, Austin and Houston." />
      <meta
        name="keywords"
        content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades, las vegas , dallas, henderson, fort worth, texas"
      />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="texas-office" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Drop Shade</span> San Antonio
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE is proudly serving Texas with our high quality custom products including patio, interior and exterior shade options and fabrics.
            Please contact us for more information and a free quote.
          </p>
          <p className="paragraph-text">We proudly install all over Dallas, Fort Worth, San Antonia, Austin and Houston.</p>
          <p className="paragraph-text" style={{ fontSize: '30px' }}>
            Call for a quote{' '}
            <a href="sms:2143100222" style={{ whiteSpace: 'nowrap' }}>
              214-310-0222
            </a>
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-1.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-1-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-1-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-2.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-2-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-2-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-3.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-3-sm.jpg')}
                width="500"
                height="800"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/texas-office/texas-drop-shade-3-sm.jpg')}
                    alt="Texas Drop Shade"
                    className="vertical-span-2"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-8.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-8-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-8-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-9.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-9-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-9-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-10.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-10-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-10-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-11.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-11-sm.jpg')}
                width="800"
                height="360"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/texas-office/texas-drop-shade-11-sm.jpg')}
                    alt="Texas Drop Shade"
                    className="horizontal-span-2"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-13.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-13-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-13-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-15.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-15-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/texas-office/texas-drop-shade-15-sm.jpg')} alt="Texas Drop Shade" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-southridge.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-southridge-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/texas-office/texas-drop-shade-southridge-sm.jpg')}
                    alt="Texas Drop Shade"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/texas-office/texas-drop-shade-lakemont.jpg')}
                thumbnail={require('../../assets/images/texas-office/texas-drop-shade-lakemont-sm.jpg')}
                width="800"
                height="500"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/texas-office/texas-drop-shade-lakemont-sm.jpg')}
                    alt="Texas Drop Shade"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default TexasOffice
