/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/photoswipe.css'

const NewConstruction = () => (
  <>
    <Helmet>
      <title>New Construction</title>
      <meta
        name="description"
        content="Dropshades that can be intergrated into the framing of new construction.  Information for project managers,  designers and architects."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="new-construction" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>New</span> Construction
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE has many shade systems that can be integrated into a new home or commercial project. If you are wanting to hide the shade roller
            and tracks and are in the construction phase this may be the way to go. We carry a large selection of rough in components so you can
            provide the option of a seemless fit.
          </p>
          <p className="paragraph-text">Then the shade tracks and upper head box can be completely recessed and out of view.</p>
          <p className="paragraph-text">
            This will need to be done during the construction phase. We can help during this process so everything fits perfectly.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/patio-shades/new-construction-01.jpg')}
                thumbnail={require('../../assets/images/patio-shades/new-construction-sm-01.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/new-construction-sm-01.jpg')} alt="New Construction" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/new-construction-02.jpg')}
                thumbnail={require('../../assets/images/patio-shades/new-construction-sm-02.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/new-construction-sm-02.jpg')} alt="New Construction" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/new-construction-03.jpg')}
                thumbnail={require('../../assets/images/patio-shades/new-construction-sm-03.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/new-construction-sm-03.jpg')} alt="New Construction" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/new-construction-04.jpg')}
                thumbnail={require('../../assets/images/patio-shades/new-construction-sm-04.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/patio-shades/new-construction-sm-04.jpg')} alt="New Construction" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default NewConstruction
