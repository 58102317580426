/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Gallery, Item } from 'react-photoswipe-gallery'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const CommercialProjects = () => (
  <>
    <Helmet>
      <title>Drop Shades Commercial Projects - Las Vegas Henderson</title>
      <meta name="description" content="Custom interior and exterior drop shades for sun control and comfort." />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="commercial-projects" />
    </Helmet>
    <main>
      <Splide
        id="splideHeaderImages"
        options={{
          type: 'loop',
          rewind: false,
          width: '100vw',
          autoHeight: true,
          cover: true,
          lazyLoad: 'nearby',
          speed: 500,
          interval: 3000,
          autoplay: true,
          pauseOnHover: false,
          pauseOnFocus: true,
          throttle: 0,
          breakpoints: {
            1200: {
              arrows: false
            }
          },
          gap: 0,
          padding: 0
        }}
        aria-label="Header Images"
      >
        <SplideSlide>
          <img src={require('../../assets/images/slides/commercial-projects/commercial-slide-1.jpg')} alt="Slide One" />
          <div className="slide-tag">
            <p className="heading-one">RAINFOREST CAFE</p>
          </div>
        </SplideSlide>
        <SplideSlide>
          <img src={require('../../assets/images/slides/commercial-projects/commercial-slide-2.jpg')} alt="Slide Two" />
          <div className="slide-tag">
            <p className="heading-one">MGM GRAND</p>
          </div>
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Commercial</span> PROJECTS
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE carries a vast selection of commercial high-end products, from heavy-duty exterior drop shades to complete retractable shading
            awning systems that can shade an entire outdoor seating area. DROPSHADE has the talent and the know-how to get the job that you want done
            right.
          </p>
          <p className="paragraph-text">
            We carry a full line of designer eco-friendly sun control fabrics that compliment many of todays' cutting-edge commercial interior and
            exterior visions.
          </p>
          <p className="paragraph-text">
            Our client lists include: Nellis AFB - St. Rose Dominican Hospital - Community College of Southern Nevada - Callville Bay - Excalibur
            Hotel - MGM - Sam Boyd Gaming - Ritz Carlton - Del Webb - California Hotel - Las Vegas Valley Water District - City Center - One Queens
            Ridge - Panorama Towers - Turnberry Towers - Sky - Palms Place - Hard Rock and many others.
          </p>
          <p className="paragraph-text">
            What our customers have to say: "It is nice dealing with the factory outlet. Your team has done an excellent job of outfitting the sales
            office with shades that assists in blocking the heat from the sun. As a side note, many residents have also expressed satisfaction with
            your work on a daily basis. Thanks for your assistance and great work."
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Hotels</span> &amp; Casinos
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            Check out one of the projects we did for the MGM in the Grand Garden Arena. They needed shades to black-out the movie screens when not in
            use. It's not what we normally do but it turned out great.{' '}
          </p>
          <p className="paragraph-text">
            "Dear Dropshade, Thanks for the much needed assistance at the Grand Garden Arena. Before your shades and during a show, the giant
            projector screens were unsightly and caused a distraction. Now, we can easily hide them at a touch of a button. Thanks again for your help
            with the initial design and installation. Let us know when you want those front row seats."
            <span className="testimonial_signature">Dana - Lead Engineer, MGM</span>
          </p>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery margin-top">
              <Item
                original={require('../../assets/images/commercial-projects/mgm-grand-lg.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mgm-grand-sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/mgm-grand-sm.jpg')} alt="MGM Grand" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mgm-grand-2-lg.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mgm-grand-2-sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/mgm-grand-2-sm.jpg')} alt="MGM Grand" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/citycenter1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/citycenter1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/citycenter1-small.jpg')}
                    alt="MGM-Mirage City Center"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/citycenter2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/citycenter2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/citycenter2-small.jpg')} alt="MGM-Mirage" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mgm1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mgm1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/mgm1-small.jpg')} alt="MGM" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mgm2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mgm2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/mgm2-small.jpg')} alt="MGM" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mgm3.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mgm3-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/mgm3-small.jpg')} alt="MGM" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mgm4.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mgm4-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/mgm4-small.jpg')} alt="MGM" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mandalay-bay-poolside-bar1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mandalay-bay-poolside-bar1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/mandalay-bay-poolside-bar1-small.jpg')}
                    alt="Mandalay Bay Poolside Bar"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/mandalay-bay-poolside-bar2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/mandalay-bay-poolside-bar2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/mandalay-bay-poolside-bar2-small.jpg')}
                    alt="Mandalay Bay Poolside Bar"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Restaurants</span> &amp; Bars
            </h2>
            <p />
          </section>
          <p className="paragraph-text">Rainforest Cafe, FIREFLY, Trattoria Reggiano, Rachel's KITCHEN, HUSSONG'S CANTINA </p>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery margin-top">
              <Item
                original={require('../../assets/images/commercial-projects/planet-hollywood-lg.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/planet-hollywood-sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/planet-hollywood-sm.jpg')}
                    alt="Planet Hollywood"
                  />
                )}
              </Item>

              <Item
                original={require('../../assets/images/commercial-projects/firefly1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/firefly1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/firefly1-small.jpg')} alt="Firefly" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/firefly2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/firefly2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/commercial-projects/firefly2-small.jpg')} alt="Firefly" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-60.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-60-sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-60-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-61.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-61-sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-61-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/drop-shade-restaurant-01.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-restaurant-01sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/drop-shade-restaurant-01sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/drop-shade-restaurant-02.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-restaurant-02sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/drop-shade-restaurant-02sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/drop-shade-restaurant-03.jpg')}
                thumbnail={require('../../assets/images/patio-shades/drop-shade-restaurant-03sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/drop-shade-restaurant-03sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-62.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-62-sm.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-62-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Craig Kenny</span> Building
            </h2>
            <p />
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery margin-top">
              <Item
                original={require('../../assets/images/commercial-projects/craigkenny1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/craigkenny1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/craigkenny1-small.jpg')}
                    alt="Craig Kenny Building"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/craigkenny2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/craigkenny2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/craigkenny2-small.jpg')}
                    alt="Craig Kenny Building"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Faith Lutheran</span> Church
            </h2>
            <p />
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery margin-top">
              <Item
                original={require('../../assets/images/commercial-projects/church1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/church1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/church1-small.jpg')}
                    alt="Faith Lutheran Church"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/church2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/church2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/church2-small.jpg')}
                    alt="Faith Lutheran Church"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>NU Sanctuary</span> Lounge
            </h2>
            <p />
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery margin-top">
              <Item
                original={require('../../assets/images/commercial-projects/sanctuary-loung1.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/sanctuary-loung1-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/sanctuary-loung1-small.jpg')}
                    alt="Sanctuary Lounge"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/commercial-projects/sanctuary-loung2.jpg')}
                thumbnail={require('../../assets/images/commercial-projects/sanctuary-loung2-small.jpg')}
                width="700"
                height="525"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/commercial-projects/sanctuary-loung2-small.jpg')}
                    alt="Sanctuary Lounge"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
    </main>
  </>
)
export default CommercialProjects
