/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Video } from '@splidejs/splide-extension-video'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { Link } from 'react-router-dom'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'
import 'photoswipe/photoswipe.css'

const InteriorShades = () => (
  <>
    <Helmet>
      <title>Interior Drop Shades, Retractable Solar Window Treatments</title>
      <meta
        name="description"
        content="DROPSHADE manufactures interior shading and sun control light filtering solar treatments with many installations on the Las Vegas Strip including VEER Towers, Mandarin, Panorama Towers, Sky Towers, and CityCenter."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/interior-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="shade-page" />
    </Helmet>

    <main>
      <Splide
        id="splideHeaderVideo"
        options={{
          autoHeight: true,
          arrows: false,
          cover: true,
          pagination: false,
          video: {
            autoplay: false,
            mute: false,
            playerOptions: {
              youtube: {
                playlist: undefined
              }
            },
            loop: true
          }
        }}
        extensions={{ Video }}
        aria-label="Header Video"
      >
        <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=-NXxxj92wS4&list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL">
          <img src={require('../../assets/images/slides/home/slide-3.jpg')} alt="Interior Shade Header Video" />
        </SplideSlide>
      </Splide>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Interior Motorized</span> SHADES
            </h2>
            <p />
          </section>
          <p className="paragraph-text">Simple and effective interior shade control.</p>
          <p className="paragraph-text">
            DROPSHADE manufactures custom high quality motorized shades for residential homes, high-rise condos, and commercial office projects. We
            offer a vast selection of custom VISI fabrics, blackout fabrics, tracking systems and hard valance header options. Our shades can be
            operated by wall or hand remote and even use timers and/or computer controls. They can also be integrated into a homes automation system
            or cell phone using our Smart Home module. That way you can have direct control in addition to your home automation system. We also offer
            a manual system as well. DROPSHADE specializes in a larger area shade system where floor to ceiling coverage is needed so there is no
            fabric openings or gaps within the glass area.
          </p>
          <p className="paragraph-text">
            Get in touch with us and we'll be happy to consult with you to fully understand your wishes, and we'll work to your specifications for the
            interior shades that best compliment your project.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-42.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-42-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-42-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-43.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-43-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-43-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-01.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-01sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-01sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-02.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-02sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-02sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-04.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-04sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-04sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-05.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-05sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-05sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-06.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-06sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-06sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-07.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-07sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-07sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/shade-interior-ridges-1.jpg')}
                thumbnail={require('../../assets/images/interior-shades/shade-interior-ridges-1sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/shade-interior-ridges-1sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/shade-interior-ridges-2.jpg')}
                thumbnail={require('../../assets/images/interior-shades/shade-interior-ridges-2sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/shade-interior-ridges-2sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-38.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-38sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-38sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-39.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-39sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-39sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-40.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-40sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-40sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-41.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-41sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-41sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/dropshade-interior-2017-03.jpg')}
                thumbnail={require('../../assets/images/interior-shades/dropshade-interior-2017-03s.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/dropshade-interior-2017-03s.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/2016-drop-shade-interior-01.jpg')}
                thumbnail={require('../../assets/images/interior-shades/2016-dropshade-interior-01s.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/2016-dropshade-interior-01s.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/dropshade-interior-2017-01.jpg')}
                thumbnail={require('../../assets/images/interior-shades/dropshade-interior-2017-01s.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/dropshade-interior-2017-01s.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/dropshade-interior-2017-02.jpg')}
                thumbnail={require('../../assets/images/interior-shades/dropshade-interior-2017-02s.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/dropshade-interior-2017-02s.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-07.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-07-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-07-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/dropshade-interior-2017-04.jpg')}
                thumbnail={require('../../assets/images/interior-shades/dropshade-interior-2017-04s.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/dropshade-interior-2017-04s.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-01.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-01-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-01-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-02.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-02-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-02-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-03.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-03-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-03-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-04.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-04-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-04-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-05.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-05-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-05-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-06.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-06-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-06-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-09.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-09-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-09-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-10.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-10-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-10-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-13.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-13-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-13-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-14.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-14-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-14-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-17.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-17-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-17-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-18.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-18-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-18-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-19.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-19-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-19-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-20.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-20-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-20-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-25.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-25-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-25-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-26.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-26-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-26-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-27.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-27-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-27-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-28.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-28-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-28-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-31.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-31-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-31-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-32.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-32-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-32-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-33.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-33-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-33-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-34.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-34-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-34-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <div className="interior-footer-links">
            <Link to="/veer-towers">Veer Towers</Link>
            <Link to="/waldorf-astoria">Waldorf Astoria</Link>
            <Link to="/the-martin">The Martin</Link>
            <Link to="/panorama-towers">Panorama Towers</Link>
          </div>
        </div>
      </section>
    </main>
  </>
)
export default InteriorShades
