/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'

const PanoramaTours = () => (
  <>
    <Helmet>
      <title>Panorama Towers</title>
      <meta name="description" content="DropShade The Panorama Towers" />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="panorama-tours" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Panorama</span> Towers
            </h2>
            <p />
          </section>
          <p className="paragraph-text">DropShade is the preferred vendor for for The Panorama Towers Las Vegas strip.</p>
        </div>
      </section>
      {/* <section class="content-section-wrapper content-section-bg-b">
              <div class="content-section">
                <section class="img-gallery" itemscope itemtype="http://schema.org/ImageGallery">
                    <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/martin/martin-1.jpg">
                            <img itemprop="thumbnail" src="images/martin/martin-1-sm.jpg" alt="The Martin Images" />
                        </a>
                    </figure>
                    <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/martin/martin-2.jpg">
                            <img itemprop="thumbnail" src="images/martin/martin-2-sm.jpg" alt="The Martin Images" />
                        </a>
                    </figure>
                    <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/martin/martin-3.jpg">
                            <img itemprop="thumbnail" src="images/martin/martin-3-sm.jpg" alt="The Martin Images" />
                        </a>
                    </figure>
                </section>
              </div>
          </section> */}
    </main>
  </>
)
export default PanoramaTours
