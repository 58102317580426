/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'

const FabricsSwatchCenter = () => (
  <>
    <Helmet>
      <title>Dropshade Fabric Swatch Center</title>
      <meta
        name="description"
        content="Dropshade offers a large selection of shade motors, controls and solar fabrics. Such as Somfy, Nice, Maddog 20, Texteline, Phifer, VISI any many others."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/fabrics-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="motors-fabrics-page" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Fabric </span>Swatch Center
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            DROPSHADE carries a vast selection of high quality, sun, solar and designer fabrics from 1, 3 and 5% densities all the way up to 100%
            blackout fabric. Contact us for a FREE sample card and to help you decide what’s the best fabric for you.
          </p>
          <p className="paragraph-text">Please feel free to download some of our most popular fabric samples below</p>
          <section className="fabrics-gallery">
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/almond-brown-VISI.jpg')} alt="Almond Brown VISI Fabric" />
              </div>
              <p className="name">ALMOND BROWN</p>
              <a
                href={require('../../assets/images/fabrics/visi/almond-brown-VISI.jpg')}
                target="_blank"
                className="btn-swatch-save"
                rel="noreferrer"
              >
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/bermuda-VISI.jpg')} alt="Bermuda VISI Fabric" />
              </div>
              <p className="name">Bermuda</p>
              <a href={require('../../assets/images/fabrics/visi/bermuda-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/carmel-tweed-VISI.jpg')} alt="Carmel Tweed VISI Fabric" />
              </div>
              <p className="name">Carmel Tweed</p>
              <a
                href={require('../../assets/images/fabrics/visi/carmel-tweed-VISI.jpg')}
                target="_blank"
                className="btn-swatch-save"
                rel="noreferrer"
              >
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/cashmere-elite-VISI.jpg')} alt="Cashmere Elite VISI Fabric" />
              </div>
              <p className="name">Cashmere Elite</p>
              <a
                href={require('../../assets/images/fabrics/visi/cashmere-elite-VISI.jpg')}
                target="_blank"
                className="btn-swatch-save"
                rel="noreferrer"
              >
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/cosmo-VISI.jpg')} alt="Cosmo VISI Fabric" />
              </div>
              <p className="name">Cosmo</p>
              <a href={require('../../assets/images/fabrics/visi/cosmo-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/galaxy-VISI.jpg')} alt="Galaxy VISI Fabric" />
              </div>
              <p className="name">Galaxy</p>
              <a href={require('../../assets/images/fabrics/visi/galaxy-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/honey-beige-VISI.jpg')} alt="Honey Beige VISI Fabric" />
              </div>
              <p className="name">Honey Beige</p>
              <a href={require('../../assets/images/fabrics/visi/honey-beige-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/mocha-VISI.jpg')} alt="Mocha VISI Fabric" />
              </div>
              <p className="name">Mocha</p>
              <a href={require('../../assets/images/fabrics/visi/mocha-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/pewter-VISI.jpg')} alt="Pewter VISI Fabric" />
              </div>
              <p className="name">Pewter</p>
              <a href={require('../../assets/images/fabrics/visi/pewter-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/pistaccio-VISI.jpg')} alt="Pistaccio VISI Fabric" />
              </div>
              <p className="name">Pistaccio</p>
              <a href={require('../../assets/images/fabrics/visi/pistaccio-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/platinum-VISI.jpg')} alt="Platinum VISI Fabric" />
              </div>
              <p className="name">Platinum</p>
              <a href={require('../../assets/images/fabrics/visi/platinum-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/quartz-VISI.jpg')} alt="Quartz VISI Fabric" />
              </div>
              <p className="name">Quartz</p>
              <a href={require('../../assets/images/fabrics/visi/quartz-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/stucco-VISI.jpg')} alt="Stucco VISI Fabric" />
              </div>
              <p className="name">Stucco</p>
              <a href={require('../../assets/images/fabrics/visi/stucco-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/tobacco-VISI.jpg')} alt="Tabacco VISI Fabric" />
              </div>
              <p className="name">Tobacco</p>
              <a href={require('../../assets/images/fabrics/visi/tobacco-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
            <article className="swatch-wrapper">
              <div className="swatch">
                <img src={require('../../assets/images/fabrics/visi/white-VISI.jpg')} alt="White VISI Fabric" />
              </div>
              <p className="name">White</p>
              <a href={require('../../assets/images/fabrics/visi/white-VISI.jpg')} target="_blank" className="btn-swatch-save" rel="noreferrer">
                Save Swatch
              </a>
            </article>
          </section>
        </div>
      </section>
    </main>
  </>
)
export default FabricsSwatchCenter
