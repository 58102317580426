import { Routes, Route } from 'react-router-dom'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import Home from './routes/Home'
import About from './routes/AboutUs'
import FactoryTour from './routes/FactoryTour'
import Testimonials from './routes/Testimonials'
import ExteriorShades from './routes/ExteriorShades'
import InteriorShades from './routes/InteriorShades'
import TwonOneShades from './routes/2n1Shades'
import FabricsSwatchCenter from './routes/FabricsSwatchCenter'
import MotorControlOptions from './routes/MotorControlOptions'
import CommercialProjects from './routes/CommercialProjects'
import NewConstruction from './routes/NewConstruction'
import DIY from './routes/DIY'
import IdeaGallery from './routes/IdeaGallery'
import WindLoadChart from './routes/WindLoadChart'
import TexasOffice from './routes/TexasOffice'
import ArizonaOffice from './routes/ArizonaOffice'
import PanoramaTours from './routes/PanoramaTours'
import TheMartin from './routes/TheMartin'
import WaldorfAstoria from './routes/WaldorfAstoria'
import VeerTowers from './routes/VeerTowers'
import ContactUs from './routes/ContactUs'

function App() {
  return (
    <>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/factory-tour" element={<FactoryTour />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/exterior-shades" element={<ExteriorShades />} />
          <Route path="/interior-shades" element={<InteriorShades />} />
          <Route path="/2-n-1-shades" element={<TwonOneShades />} />
          <Route path="/fabrics-swatch-center" element={<FabricsSwatchCenter />} />
          <Route path="/motor-control-options" element={<MotorControlOptions />} />
          <Route path="/commercial-projects" element={<CommercialProjects />} />
          <Route path="/new-construction" element={<NewConstruction />} />
          <Route path="/diy" element={<DIY />} />
          <Route path="/idea-gallery" element={<IdeaGallery />} />
          <Route path="/wind-load-chart" element={<WindLoadChart />} />
          <Route path="/texas-office" element={<TexasOffice />} />
          <Route path="/arizona-office" element={<ArizonaOffice />} />
          <Route path="/panorama-tours" element={<PanoramaTours />} />
          <Route path="/the-martin" element={<TheMartin />} />
          <Route path="/waldorf-astoria" element={<WaldorfAstoria />} />
          <Route path="/veer-towers" element={<VeerTowers />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  )
}

export default App
