/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Video } from '@splidejs/splide-extension-video'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'

const Home = () => {
  console.log('Home Page')
  return (
    <>
      <Helmet>
        <title>DROPSHADE - Patio Drop Shades, Motorized Solar Screens and Interior Retractable Window Shades</title>
        <meta
          name="description"
          content="DROPSHADE #1 shade dealer of sun control systems in the world.  We carry patio shades, motorized interior shades, exterior retractable screens, motorized 2n1 awnings, solar screens, retractable pull chain shades, office shades, window treatments. Plus solar fabrics, blackout fabrics, shear Dreamweave fabrics, motors, remotes and controls."
        />
        <meta
          name="keywords"
          content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades, window treatments, roller shades"
        />
        <meta property="og:title" content="DropShade" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.dropshade.com" />
        <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
        <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
        <meta property="og:site_name" content="DropShade" />
        <body data-page="home" />
      </Helmet>

      <main>
        <Splide
          id="splideHeaderImages"
          options={{
            type: 'loop',
            rewind: false,
            width: '100vw',
            autoHeight: true,
            cover: true,
            lazyLoad: 'nearby',
            speed: 500,
            interval: 3000,
            autoplay: true,
            pauseOnHover: false,
            pauseOnFocus: true,
            throttle: 0,
            breakpoints: {
              1200: {
                arrows: false
              }
            },
            gap: 0,
            padding: 0
          }}
          aria-label="Header Images"
        >
          <SplideSlide>
            <img src={require('../../assets/images/slides/home/slide-1.jpg')} alt="Slide One" />
            <div className="slide-tag">
              <p className="heading-one">2n1 SHADES</p>
              <p className="heading-two">Expand Your Usable Space</p>
              <Link to="/2-n-1-shades" className="link-slider-more">
                VIEW EXAMPLES
              </Link>
            </div>
          </SplideSlide>
          <SplideSlide>
            <img src={require('../../assets/images/slides/home/slide-2.jpg')} alt="Slide Two" />
            <div className="slide-tag">
              <p className="heading-one">EXTERIOR SHADES</p>
              <p className="heading-two">Create a Patio Screen Room</p>
              <Link to="/exterior-shades" className="link-slider-more">
                VIEW EXAMPLES
              </Link>
            </div>
          </SplideSlide>
          <SplideSlide>
            <img src={require('../../assets/images/slides/home/slide-3.jpg')} alt="Slide Three" />
            <div className="slide-tag">
              <p className="heading-one">INTERIOR SHADES</p>
              <p className="heading-two">Interior Sun Control and Blackout Options</p>
              <Link to="/interior-shades" className="link-slider-more">
                VIEW EXAMPLES
              </Link>
            </div>
          </SplideSlide>
        </Splide>
        <section className="content-section-wrapper content-section-bg-a">
          <div className="content-section">
            <section className="preview-cards">
              <article className="preview-card">
                <picture>
                  <img src={require('../../assets/images/home-page/preview-interior.jpg')} alt="Interior Shades" aria-label="Interior Shades" />
                </picture>
                <div className="summary">
                  <p className="heading">INTERIOR SHADES</p>
                  <p className="text">From sun protection to blackout fabrics we have the shade you are looking for.</p>
                </div>
                <div className="link-cont">
                  <Link to="/interior-shades" className="a-learn-more">
                    LEARN MORE
                  </Link>
                </div>
              </article>
              <article className="preview-card">
                <picture>
                  <img src={require('../../assets/images/home-page/preview-exterior.jpg')} alt="Interior Shades" aria-label="Interior Shades" />
                </picture>
                <div className="summary">
                  <p className="heading">EXTERIOR SHADES</p>
                  <p className="text">Create an outdoor sun control room with a touch of a button.</p>
                </div>
                <div className="link-cont">
                  <Link to="/exterior-shades" className="a-learn-more">
                    LEARN MORE
                  </Link>
                </div>
              </article>
              <article className="preview-card">
                <picture>
                  <img src={require('../../assets/images/home-page/preview-2n1.jpg')} alt="Interior Shades" aria-label="Interior Shades" />
                </picture>
                <div className="summary">
                  <p className="heading">2N1 SHADES</p>
                  <p className="text">Provide more usability and versatility with our expandable shading options.</p>
                </div>
                <div className="link-cont">
                  <Link to="/2-n-1-shades" className="a-learn-more">
                    LEARN MORE
                  </Link>
                </div>
              </article>
              <article className="preview-card">
                <picture>
                  <img src={require('../../assets/images/home-page/preview-interior.jpg')} alt="Interior Shades" aria-label="Interior Shades" />
                </picture>
                <div className="summary">
                  <p className="heading">SHADE FABRIC COLORS</p>
                  <p className="text">
                    Customize your shades with an infinite amount of high quality designer fabrics and motorized control options.
                  </p>
                </div>
                <div className="link-cont">
                  <Link to="/fabrics-swatch-center" className="a-learn-more">
                    LEARN MORE
                  </Link>
                </div>
              </article>
            </section>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-b">
          <div className="content-section">
            <section className="section-title">
              <h2>
                <span>Interior &amp; Exterior</span> SHADES
              </h2>
              <p />
            </section>
            <p className="paragraph-text">
              From the smallest window opening to expansive commercial shading areas, DROPSHADE has what it takes to create innovative and effective
              shading solutions. In fact DROPSHADE designs and builds 1000's of retractable shades each year!
            </p>
            <p className="paragraph-text">
              DROPSHADE is family-owned and operated. Tradition, integrity and superior customer service have made us a leading manufacturer of sun
              control systems. We are continuously the number-one choice of builders, contractors and homeowners when it comes to their shading needs.
            </p>
            <p className="paragraph-text">
              For over 20 years we've been a renowned innovator of shading materials, structural designs and installation methods that have been
              adopted by the industry as a whole. Today, DROPSHADE continues to be family-owned and operated, and committed to providing exceptional
              products and craftsmanship to each and every customer we serve.
            </p>
            <div className="in-ex-examples">
              <div>
                <img src={require('../../assets/images/interior-shades/shade-interior-ridges-1sm.jpg')} alt="Shade Interior Ridges" />
              </div>
              <div>
                <img src={require('../../assets/images/patio-shades/patio-drop-shade-70-sm.jpg')} alt="Dropshade 70" />
              </div>
              <div>
                <img src={require('../../assets/images/2-n-1-shades/2n1-dropshade-59-sm.jpg')} alt="Dropshade 59" />
              </div>
              <div>
                <img src={require('../../assets/images/2-n-1-shades/2n1-shade-2sm.jpg')} alt="Dropshade 2" />
              </div>
            </div>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-c">
          <div className="content-section">
            <section className="section-title">
              <h2>
                <span>Recent</span> VIDEOS
              </h2>
              <p />
            </section>
            <section className="featured-project-videos">
              <Splide
                id="splideFeatureProjectsVideo"
                options={{
                  fixedHeight: 560,
                  fixedWidth: 315,
                  padding: 0,
                  gap: 30,
                  type: 'loop',
                  snap: true,
                  // cover: true,
                  video: {
                    loop: true
                  }
                }}
                extensions={{ Video }}
                aria-label="Featured Products"
              >
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=ocnvLPZ0c34">
                  <img src={require('../../assets/images/home-page/project-video-preview-1.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=7cfBj_OgzN8">
                  <img src={require('../../assets/images/home-page/project-video-preview-2.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=8UcUuFRLiow">
                  <img src={require('../../assets/images/home-page/project-video-preview-3.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=VNv8V9csV8c">
                  <img src={require('../../assets/images/home-page/project-video-preview-4.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=cV-AJ5MOSW0">
                  <img src={require('../../assets/images/home-page/project-video-preview-5.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=Kw33Fozwa40">
                  <img src={require('../../assets/images/home-page/project-video-preview-6.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=HzNinkAGp4A">
                  <img src={require('../../assets/images/home-page/project-video-preview-7.jpg')} alt="Home" />
                </SplideSlide>
                <SplideSlide data-splide-youtube="https://www.youtube.com/watch?v=rxhI9jFdj6Q">
                  <img src={require('../../assets/images/home-page/project-video-preview-8.jpg')} alt="Home" />
                </SplideSlide>
              </Splide>
            </section>
            <div className="view-all-links white">
              <a href="https://www.youtube.com/user/Dropshade" target="_blank" rel="noreferrer">
                View Our YouTube Channel
              </a>
            </div>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-a">
          <div className="content-section">
            <section className="section-title">
              <h2>
                <span>Experience</span> DROPSHADE
              </h2>
              <p />
            </section>
            <p className="paragraph-text">
              Regardless of the size, scope or complexity of your shade and awning wishes, we've got a solution or we'll come up with one. Though
              we're small enough to still be owned by the same family that established the DropShade brand more than 20 years ago, we're big enough to
              handle the customization of your interior and outdoor drop shade needs. Please contact us for more information or a free quote.
            </p>
          </div>
        </section>
        <section className="content-section-wrapper content-section-bg-b">
          <div className="content-section">
            <Splide
              id="splideTestimonials"
              options={{
                type: 'loop',
                rewind: false,
                width: '100%',
                autoHeight: true,
                lazyLoad: 'nearby',
                speed: 500,
                interval: 3000,
                autoplay: true,
                pauseOnHover: false,
                pauseOnFocus: false,
                gap: 0,
                padding: 0,
                pagination: false
              }}
              aria-label="Testimonials"
            >
              <SplideSlide>
                <div className="testimonial testimonial-one">
                  <p className="customer-testimonial">
                    <span>
                      Hi, Just a note to let you know how thrilled I am with my drop shades. Everyone that I have dealt with from Dropshade has been
                      professional, organized and helpful. From the first day I came into your factory I was treated like one of the family. Thanks
                      once again for the excellent job and outstanding product - my patio is now complete.
                    </span>
                  </p>
                  <p className="customer-name">Carol Clemens</p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="testimonial testimonial-two">
                  <p className="customer-testimonial">
                    <span>
                      Great shades! Have had these shades for the past two summers. They work as well now as they did when they were first installed.
                      With the eastern and western exposure on our windows, the Drop Shades were absolutely the best investment we made for our new
                      home. Not only did it protect our furniture from the sun, but, it actually cooled down our home during the hot summer months.
                    </span>
                  </p>
                  <p className="customer-name">Trisha Stone</p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="testimonial testimonial-three">
                  <p className="customer-testimonial">
                    <span>
                      Dear Dropshade, I would like to express how satisfied we are on the quality product you have installed on our home in Anthem.
                      Our patio opening is extremely wide (27 foot to be exact) and the way the sun was coming in was making it unbearable to sit
                      outside. Your product has helped us with a solution to this problem and in addition helps with the wind too. Thanks for a high
                      quality product that upholds a high standard of engineering and design.
                    </span>
                  </p>
                  <p className="customer-name">Valerian Podmolick</p>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="testimonial testimonial-four">
                  <p className="customer-testimonial">
                    <span>
                      The patio drop shades are wonderful at reducing the heat we were getting with the afternoon sun. We can now enjoy our evening
                      dinners on our patio. Thank you for a job well done.
                    </span>
                  </p>
                  <p className="customer-name">Hannah Mcfarland</p>
                </div>
              </SplideSlide>
            </Splide>
            <div className="view-all-links">
              <Link to="/testimonials">View All Testimonials</Link>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Home
