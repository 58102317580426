import { Link } from 'react-router-dom'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer>
      <section className="inner-footer">
        <article className="footer-grid">
          <div className="company-details">
            <div className="logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315.79 54.75">
                <path className="logo-cls-1" d="M100.5,33.54A14.14,14.14,0,1,1,86.36,19.4,14.14,14.14,0,0,1,100.5,33.54Z" />
                <path
                  className="logo-cls-2"
                  d="M8.41,21h1.92a12.37,12.37,0,0,1,9.11,3.43,12.49,12.49,0,0,1,3.44,9.14,12.49,12.49,0,0,1-3.41,9.13,12.32,12.32,0,0,1-9.06,3.41h-2Zm1.95-8.33H0V54.49H10.36a21.4,21.4,0,0,0,15.3-6.15c7.49-7.46,7.49-22.06,0-29.56a21.33,21.33,0,0,0-15.3-6.17Z"
                />
                <path
                  className="logo-cls-2"
                  d="M42.82,32.68V20.86h4.71c1.74,0,3.93.45,5.15,1.7a5.26,5.26,0,0,1,1.67,4.11A5.75,5.75,0,0,1,52.68,31a4.67,4.67,0,0,1-3.85,1.66Zm-8.41-20.2,0,42h8.4V38.09l10.73,16.4H63.82l-9.47-14.6c2.76-1,4.24-1.77,6-4.27A12.72,12.72,0,0,0,63,27.71v-1.3A12.29,12.29,0,0,0,59,16.78a15.3,15.3,0,0,0-10.9-4.3Z"
                />
                <path
                  className="logo-cls-2"
                  d="M77.36,42.49a12.22,12.22,0,0,1-3.73-9,12.3,12.3,0,0,1,3.73-9,12.62,12.62,0,0,1,18,0,12.25,12.25,0,0,1,3.75,9,12.17,12.17,0,0,1-3.75,9,12.69,12.69,0,0,1-18,0m-6-23.92a20.54,20.54,0,0,0-6.22,15,20.43,20.43,0,0,0,6.22,15,21.15,21.15,0,0,0,30,0,20.37,20.37,0,0,0,6.24-15,20.48,20.48,0,0,0-6.24-15,21.1,21.1,0,0,0-30,0"
                />
                <path
                  className="logo-cls-2"
                  d="M119.19,33.51V20.91h6.27a6,6,0,0,1,4.45,1.88,6.16,6.16,0,0,1,1.84,4.45,6.3,6.3,0,0,1-6.29,6.3Zm0-21h-8.41v42h8.41V41.89h6.27a14.24,14.24,0,0,0,10.38-4.29,14.07,14.07,0,0,0,4.32-10.36,14.13,14.13,0,0,0-4.32-10.38,14.15,14.15,0,0,0-10.38-4.32Z"
                />
                <path
                  className="logo-cls-2"
                  d="M146,16a12.23,12.23,0,0,0-3.75,9,9.56,9.56,0,0,0,1.71,5.8,16.69,16.69,0,0,0,7.89,5.8c2.94,1,2.21.76,5.23,1.88,1.43.52,2.13,1.66,2.13,3.46A4.27,4.27,0,0,1,155,46.16l0,.05A4.24,4.24,0,0,1,150.74,42h-8.49a12.74,12.74,0,0,0,25.48,0A9.26,9.26,0,0,0,166,36.14a16.2,16.2,0,0,0-8.15-5.52c-3-.88-2.7-.57-5.2-2.18A3.82,3.82,0,0,1,150.79,25a4.23,4.23,0,1,1,8.45,0h8.47A12.2,12.2,0,0,0,164,16a12.64,12.64,0,0,0-18,0"
                />
                <polygon
                  className="logo-cls-2"
                  points="192.69 12.54 192.69 29.32 180.12 29.32 180.12 12.54 171.71 12.54 171.71 54.49 180.12 54.49 180.12 37.7 192.69 37.7 192.69 54.49 201.09 54.49 201.09 12.54 192.69 12.54"
                />
                <path className="logo-cls-2" d="M222.09,31.9l3.7,8.72h-7.41Zm-19,22.48h9l2.74-6,.1-.31h14.29l.13.29,2.7,6.06h9.09L222.07,11.76Z" />
                <path
                  className="logo-cls-2"
                  d="M251.52,21h1.92a12.36,12.36,0,0,1,9.11,3.43A12.49,12.49,0,0,1,266,33.54a12.49,12.49,0,0,1-3.41,9.13,12.32,12.32,0,0,1-9.06,3.41h-2Zm1.95-8.33H243.11V54.49h10.36a21.4,21.4,0,0,0,15.3-6.15c7.5-7.46,7.5-22.06,0-29.56a21.33,21.33,0,0,0-15.3-6.17Z"
                />
                <polygon
                  className="logo-cls-2"
                  points="277.52 12.54 277.52 54.49 285.93 54.49 302.69 54.49 302.69 46.08 285.93 46.08 285.93 37.7 298.5 37.7 298.5 29.32 285.93 29.32 285.93 20.91 302.69 20.91 302.69 12.54 277.52 12.54"
                />
                <text className="logo-cls-3" transform="translate(302.74 14.31)">
                  ®
                </text>
              </svg>
            </div>
            <p className="footer-tagline">Buy factory Direct... Eliminate the middle man!</p>
            <div className="address-cont">
              <p>(702) 456-7742</p>
              <p>2547 E. WASHBURN RD.</p>
              <p>NORTH LAS VEGAS, NV 89081</p>
            </div>
            <div className="phone-cont">
              <p>
                <a href="tel:7024728045">Las Vegas (702) 472-8045</a>
              </p>
              <p>
                <Link to="/texas-office">Texas (214) 310-0222</Link>
              </p>
              <p>
                <Link to="/arizona-office">Arizona (480) 536-0802</Link>
              </p>
              <p>
                <a href="tel:7609698222">Palm Springs (760) 969-8222</a>
              </p>
              <p>
                <a href="tel:2399221110">Florida (239) 922-1110</a>
              </p>
              <p>
                <a href="tel:8002908072">DIY Shipping Kits (800) 290-8072</a>
              </p>
            </div>
          </div>
          <div className="footer-links fl-products">
            <p className="title">PRODUCTS</p>
            <Link to="/interior-shades">Interior Shades</Link>
            <Link to="/exterior-shades">Exterior Shades</Link>
            <Link to="/2-n-1-shades">2n1 Shades</Link>
            <Link to="/new-construction">New Construction</Link>
            <Link to="/diy">DIY Shipping</Link>
            <Link to="/idea-gallery">Idea Gallery</Link>
            <a href={require('../assets/images/Brochure 2022 - Feb 9 2022 - 5-54 PM.pdf')} target="_blank" rel="noreferrer">
              E-book Brochure
            </a>
            <Link to="/wind-load-chart">Wind Load Chart</Link>
          </div>
          <div className="footer-links fl-about">
            <p className="title">ABOUT US</p>
            <Link to="/about-us">About Us</Link>
            <Link to="/factory-tour">Factory Tour</Link>
            <Link to="/testimonials">Testimonials</Link>
          </div>
          <div className="footer-links fl-contact">
            <p className="title">CONTACT US</p>
            <Link to="/contact-us">Contact Us</Link>
            <Link to="/contact-us">Locations</Link>
          </div>
          <div className="separator" />
          <div className="contact-quote-section">
            <Link to="/contact-us#quoteForm" className="btn btn-footer-quote">
              GET A QUOTE
            </Link>
            <p className="text-us">
              You can always text us on our shop iPhone{' '}
              <a href="sms:7022772349" style={{ whiteSpace: 'nowrap' }}>
                (702) 277-2349
              </a>
            </p>
            <div className="social">
              <a href="https://www.instagram.com/dropshadelv" target="_blank" className="social-link" rel="noreferrer">
                <i className="icon icon-instagram" />
              </a>
              <a href="https://www.youtube.com/user/Dropshade" target="_blank" className="social-link" rel="noreferrer">
                <i className="icon icon-youtube" />
              </a>
            </div>
          </div>
        </article>
      </section>
      <section className="credentials-logos">
        <img src={require('../assets/images/credentials-logos.jpg')} alt="Dropshade Certified" />
      </section>
      <section className="copyright">
        <p>© {currentYear} DROPSHADE® All Rights Reserved.</p>
        <p>NV Contractors Lic C-14 #71496 100,000.00 Bid Limit</p>
        <p>C-2 Electrical Lic #77454 250,000 Bid Limit - Bonded and Insured</p>
        <p>ALL PHOTOS AND CONTENTS WITHIN ARE THE SOLE PROPERTY OF DROPSHADE® AND ANY REPRODUCTION WITHOUT WRITTEN CONSENT WILL BE PROSECUTED.</p>
      </section>
    </footer>
  )
}
