/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { Helmet } from 'react-helmet'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/photoswipe.css'

const IdeaGallery = () => (
  // const imgChanger = (id) => {
  //   document.getElementById(
  //     'shadechangerimg'
  //   ).src = `images/shade-color-changer/${id}.jpg`
  // }
  <>
    <Helmet>
      <title>Idea Gallery</title>
      <meta
        name="description"
        content="List of videos and photos of custom sun control products built by Dropshade including patio, 2 n 1, Interior and overhead shades."
      />
      <meta name="keywords" content="dropshade, dropshades, drop shade, drop shades, patio shades, interior shades, high rise shades" />
      <meta property="og:title" content="DropShade" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.dropshade.com" />
      <meta property="og:image" content="https://www.dropshade.com/images/meta/dropshade-cover.jpg" />
      <meta property="og:description" content="DROPSHADE #1 shade dealer of sun control systems." />
      <meta property="og:site_name" content="DropShade" />
      <body data-page="idea-gallery-page" />
    </Helmet>
    <main>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Idea</span> Gallery
            </h2>
            <p />
          </section>
          <p className="paragraph-text">
            Get ideas on what best fits your needs by viewing the videos and images below for our different types of shades.
          </p>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Patio</span> Drop Shades
            </h2>
            <p />
          </section>
          <section className="idea-gallery-video-wrapper">
            <div className="video-responsive">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/98VNhEsQGMw" title="YouTube video player" allowfullscreen />
            </div>
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-70.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-70-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-70-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-69.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-69-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-69-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-68.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-68-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-68-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/patio-shades/patio-drop-shade-67.jpg')}
                thumbnail={require('../../assets/images/patio-shades/patio-drop-shade-67-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/patio-shades/patio-drop-shade-67-sm.jpg')}
                    alt="Patio Dropshade Images"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>2-n-1</span> Shades
            </h2>
            <p />
          </section>
          <section className="idea-gallery-video-wrapper">
            <div className="video-responsive">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/k7IzNEEqsKs?list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL"
                title="YouTube video player"
                frameBorder="0"
                allowfullscreen
              />
            </div>
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-63.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-63-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-63-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-62.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-62-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-62-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-61.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-61-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-61-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-60.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-60-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-60-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-59.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-59-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-59-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
              <Item
                original={require('../../assets/images/2-n-1-shades/2n1-dropshade-58.jpg')}
                thumbnail={require('../../assets/images/2-n-1-shades/2n1-dropshade-58-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={require('../../assets/images/2-n-1-shades/2n1-dropshade-58-sm.jpg')} alt="2-n-1 Shades" />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-b">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>Interior</span> Shades
            </h2>
            <p />
          </section>
          <section className="idea-gallery-video-wrapper">
            <div className="video-responsive">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-NXxxj92wS4?list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL"
                title="YouTube video player"
                frameBorder="0"
                allowfullscreen
              />
            </div>
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/interior-shades/shade-interior-ridges-1.jpg')}
                thumbnail={require('../../assets/images/interior-shades/shade-interior-ridges-1sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/shade-interior-ridges-1sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/shade-interior-ridges-2.jpg')}
                thumbnail={require('../../assets/images/interior-shades/shade-interior-ridges-2sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/shade-interior-ridges-2sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-38.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-38sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-38sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-39.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-39sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-39sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      <section className="content-section-wrapper content-section-bg-a">
        <div className="content-section">
          <section className="section-title">
            <h2>
              <span>High Rise</span> Shades
            </h2>
            <p />
          </section>
          <section className="idea-gallery-video-wrapper">
            <div className="video-responsive">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hQ32URkxKZw?list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL"
                title="YouTube video player"
                frameBorder="0"
                allowfullscreen
              />
            </div>
          </section>
          <Gallery
            options={{
              counter: false,
              bgOpacity: 0.9
            }}
          >
            <div className="img-gallery dense">
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-06.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-06sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-06sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-07.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-07sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-07sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-42.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-42-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-42-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-interior-43.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-interior-43-sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-interior-43-sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-01.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-01sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-01sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-02.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-02sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-02sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-04.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-04sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-04sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
              <Item
                original={require('../../assets/images/interior-shades/drop-shade-veer-towers-05.jpg')}
                thumbnail={require('../../assets/images/interior-shades/drop-shade-veer-towers-05sm.jpg')}
                width="600"
                height="450"
              >
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={require('../../assets/images/interior-shades/drop-shade-veer-towers-05sm.jpg')}
                    alt="Interior Dropshade Images"
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </section>
      {/* <section class="content-section-wrapper content-section-bg-b">
                <div class="content-section">
                    <section class="section-title">
                        <h2><span>Drop</span> Drapes</h2>
                        <p></p>
                    </section>
                    <section class="idea-gallery-video-wrapper">
                      <div class="video-responsive">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/s3FIMD2lxAM?list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL" title="YouTube video player" frameborder="0" allowfullscreen></iframe>
                      </div>
                    </section>
                    <section class="img-gallery" itemscope itemtype="http://schema.org/ImageGallery">
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/drop-drapes/drape1-large.jpg">
                          <img itemprop="thumbnail" src="images/drop-drapes/drape1.jpg" />
                        </a>
                      </figure>
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/drop-drapes/drape2-large.jpg">
                          <img itemprop="thumbnail" src="images/drop-drapes/drape2.jpg" />
                        </a>
                      </figure>
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/drop-drapes/drape7-large.jpg">
                          <img itemprop="thumbnail" src="images/drop-drapes/drape7.jpg" />
                        </a>
                      </figure>
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/drop-drapes/drape4-large.jpg">
                          <img itemprop="thumbnail" src="images/drop-drapes/drape4.jpg" />
                        </a>
                      </figure>
                    </section>
                </div>
            </section> -->
            <!-- <section class="content-section-wrapper content-section-bg-a">
                <div class="content-section">
                    <section class="section-title">
                        <h2><span>Overhead</span> Shades</h2>
                        <p></p>
                    </section>
                    <section class="idea-gallery-video-wrapper">
                      <div class="video-responsive">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/K2vSM79rmnI?list=PLXjNJQE-WaP8xj5w9yN-qJpXqj1jmbWZL" title="YouTube video player" frameborder="0" allowfullscreen></iframe>
                      </div>
                    </section>
                    <section class="img-gallery" itemscope itemtype="http://schema.org/ImageGallery">
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/overhead-shades/overhead-drop-shade-01.jpg">
                          <img itemprop="thumbnail" src="images/overhead-shades/overhead-drop-shade-01-sm.jpg" />
                        </a>
                      </figure>
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/overhead-shades/overhead-drop-shade-02.jpg">
                          <img itemprop="thumbnail" src="images/overhead-shades/overhead-drop-shade-02-sm.jpg" />
                        </a>
                      </figure>
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/overhead-shades/overhead-drop-shade-03.jpg">
                          <img itemprop="thumbnail" src="images/overhead-shades/overhead-drop-shade-03-sm.jpg" />
                        </a>
                      </figure>
                      <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
                        <a itemprop="contentUrl" data-size="600x450" href="images/overhead-shades/overhead-drop-shade-04.jpg">
                          <img itemprop="thumbnail" src="images/overhead-shades/overhead-drop-shade-04-sm.jpg" />
                        </a>
                      </figure>
                    </section>
                </div>
            </section> -->
            <!-- <section class="content-section-wrapper content-section-bg-b">
              <div class="content-section">
                  <section class="section-title">
                      <h2><span>Shade</span> Color Changer</h2>
                      <p></p>
                  </section>
                  <section class="color-changer-wrapper">
                    <div class="changer-swatches">
                      <p class="instructions paragraph-text">(click any swatch tile to view it on the house below)</p>
                      <div class="changer-swatch-grid">
                        <div class="imgChangerSwatch" onclick="imgChanger(1);"><img src="images/shade-color-changer/tile-1-1.jpg" alt="Shade Color Swatch 1" /></div>
                        <div class="imgChangerSwatch" onclick="imgChanger(2);"><img src="images/shade-color-changer/tile-2-1.jpg" alt="Shade Color Swatch 2" /></div>
                        <div class="imgChangerSwatch" onclick="imgChanger(3);"><img src="images/shade-color-changer/tile-2-2.jpg" alt="Shade Color Swatch 3" /></div>
                        <div class="imgChangerSwatch" onclick="imgChanger(4);"><img src="images/shade-color-changer/tile-2-3.jpg" alt="Shade Color Swatch 4" /></div>
                        <div class="imgChangerSwatch" onclick="imgChanger(5);"><img src="images/shade-color-changer/tile-2-5.jpg" alt="Shade Color Swatch 5" /></div>
                        <div class="imgChangerSwatch" onclick="imgChanger(6);"><img src="images/shade-color-changer/tile-3-1.jpg" alt="Shade Color Swatch 6" /></div>
                        <div class="imgChangerSwatch" onclick="imgChanger(7);"><img src="images/shade-color-changer/tile-3-4.jpg" alt="Shade Color Swatch 7" /></div>
                      </div>
                    </div>
                    <div class="changer-house">
                      <div><img id="shadechangerimg" src="images/shade-color-changer/1.jpg" /></div>
                      <img src="images/shade-color-changer/1.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/2.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/3.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/4.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/5.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/6.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/7.jpg" style="display:none;" />
                      <img src="images/shade-color-changer/8.jpg" style="display:none;" />
                    </div>
                  </section>
              </div>
          </section> */}
    </main>
  </>
)

export default IdeaGallery
